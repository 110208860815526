import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileText, LogIn, Mail, User } from 'react-feather';
import man from '../../../assets/images/dashboard/profile.png';

import { LI, UL, Image, P } from '../../../AbstractElements';
import CustomizerContext from '../../../_helper/Customizer';
import { Account, Admin, Inbox, LogOut, Taskboard, HTTP_RESOURCE_LOGOUT,DOMAIN_COOKIE,DOMAINURL } from '../../../Constant';
import {postRequest, deleteRequest} from "../../../Services/http-request-service.js";
import SweetAlert from 'sweetalert2';

const UserHeader = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('Emay Walter');
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem('authenticated'));
  const auth0_profile = JSON.parse(localStorage.getItem('auth0_profile'));
  const token = document.cookie.substring(6);

  const domain = "localhost";
  const URL = `${DOMAINURL}:3000${HTTP_RESOURCE_LOGOUT}`;

  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || man);
    setName(localStorage.getItem('Name') ? localStorage.getItem('Name') : name);
  }, []);

  async function logout()
  {
    // Variables
    let data = {};

    // build the request data
    data = {"authorization":token};
    console.log(data);

    // send the request
    try
    {
      document.cookie = `token=; Max-Age=0; path=/; SameSite=Lax; domain=${DOMAIN_COOKIE}`;
      localStorage.removeItem('token');
      localStorage.removeItem('auth0_profile');
      localStorage.setItem('authenticated', false);
      await deleteRequest(URL,data);
      await SweetAlert.fire({ title: "Success!", text: "Logged out Successfully! Redirecting to the login page", icon: "success" });
      history(`${process.env.PUBLIC_URL}/login`);
    }
    catch (error)
    {
      SweetAlert.fire({ title: "Error!", text: "Could not Logout", icon: "error" });
      return;
    }
  }

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li id="userheader" className='profile-nav onhover-dropdown pe-0 py-0'>
      <div className='media profile-media'>
        <Image
          attrImage={{
            className: 'b-r-10 m-0',
            src: ``,
            alt: '',
          }}
        />
        <div className='media-body'>
          <span>{authenticated ? auth0_profile.name : name}</span>
          <P attrPara={{ className: 'mb-0 font-roboto' }}>
            {Admin} <i className='middle fa fa-angle-down'></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}>
      {/* <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/userProfile/${layoutURL}`),
          }}>
          <User />
          <span>{"Account"} </span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/twoFactor/${layoutURL}`),
          }}>
          <User />
          <span>{"Two Factor"} </span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/APIKeys/${layoutURL}`),
          }}>
          <Mail />
          <span>{"API Keys"}</span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/tradingLog/${layoutURL}`),
          }}>
          <Mail />
          <span>{"Trade Log"}</span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/userSubscriptions/${layoutURL}`),
          }}>
          <FileText />
          <span>{"Subscription"}</span>
        </LI> */}
        <LI attrLI={{ onClick: logout }}>
          <LogIn id="logoutlink" />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
