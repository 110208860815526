import React, { useState } from 'react';
import { Container, Row, Col, Form, Input, Label, Button } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import {postRequest} from "../Services/http-request-service.js";
import { sha3_512 } from 'js-sha3';
import { SESSION_MAXIMUM_VALID_TIME,HTTP_RESOURCE_LOGIN,DOMAINURL,DOMAIN_COOKIE } from "../Constant";

const Logins = (props) => {

  const navigate = useNavigate();
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  
  const URL = `${DOMAINURL}:3000${HTTP_RESOURCE_LOGIN}`;

  var result;
  var data;

  async function login()
  {
    // error check
    if (email === "" || password === "")
    {
      SweetAlert.fire({ title: "Error!", text: "All fields are required", icon: "error" });
      return;
    }

    // build the request data
    data = {"email":email,"hashedPassword":sha3_512(password),"code":"000000"};
    console.log(data);

    // send the request
    try
    {
      result = await postRequest(URL,data);
      document.cookie = `token=${result.token}; Max-Age=${SESSION_MAXIMUM_VALID_TIME}; SameSite=Lax; path=/; domain=${DOMAIN_COOKIE}`;
      await SweetAlert.fire({ title: "Success!", text: "Logged in Successfully! Redirecting to the home page", icon: "success" });
      navigate(`${process.env.PUBLIC_URL}/dashboard/default/Dubai`);
    }
    catch (error)
    {
      if (error.message === "The user is not unauthorized, due to the two factor")
      {
        let data2 = await SweetAlert.fire({ title: "Enter Two Factor Code", input: "text", icon: "info",showDenyButton: true,confirmButtonText: 'OK',denyButtonText: 'Cancel' });
        if (data2.isDenied)
        {
          return;
        }
        data = {"email":email,"hashedPassword":sha3_512(password),"code":data2.value};

        // send the request
        try
        {
          result = await postRequest(URL,data);
          document.cookie = `token=${result.token}; Max-Age=${SESSION_MAXIMUM_VALID_TIME}; SameSite=Lax; path=/; domain=${DOMAIN_COOKIE}`;
          await SweetAlert.fire({ title: "Success!", text: "Logged in Successfully! Redirecting to the home page", icon: "success" });
          navigate(`${process.env.PUBLIC_URL}/dashboard/default/Dubai`);
        }
        catch (error)
        {
          SweetAlert.fire({ title: "Error!", text: "Could not Login", icon: "error" });
          return;
        }
      }
      else
      {
        SweetAlert.fire({ title: "Error!", text: "Could not Login", icon: "error" });
        return;
      }
    }
  }

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img className="img-fluid for-light" src={require("../assets/images/logo/login.png")} alt="" />
                  <img className="img-fluid for-dark" src={require("../assets/images/logo/login.png")} alt="" />
                  <h4>{"SANO Healthcare"}</h4>
                </a>
              </div>
              <div className="login-main login-tab">                
                
                    <Form className="theme-form">
                      <h4>{"Sign In"}</h4>
                      <p>{"Enter your email & password to login"}</p>
                      <div className="mb-3">
                        <Label className="col-form-label">{"Email Address"}</Label>
                        <Input id="useremail" className="form-control" type="email" required="" onChange={item => setEmail(item.target.value)} placeholder="email" />
                      </div>
                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{"Password"}</Label>
                        <Input id="userpassword" className="form-control" type={togglePassword ? "text" : "password"} onChange={item => setPassword(item.target.value)} placeholder="password" required="" />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span id="togglepassword" className={togglePassword ? "" : "show"}></span></div>
                      </div>
                      <div className="login-btn mb-0">
                        <Button id="signin" color="primary" onClick={() => login(email, password)}>{"Sign In"}</Button>                        
                      </div>
                      <p className="mt-4 mb-0">{""}<a className="ms-2" onClick={() => navigate(`${process.env.PUBLIC_URL}/pages/authentication/register-simple/Dubai`)} href="#/">{"Register"}</a></p>
                      <p className="mt-4 mb-0">{""}<a className="ms-2" onClick={() => navigate(`${process.env.PUBLIC_URL}/login`)} href="#/">{"Provider Login"}</a></p>
                    </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

// export default withRouter(Logins);
export default Logins;