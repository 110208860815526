import React, { Fragment, useEffect,useRef } from 'react';
import { Breadcrumbs} from '../../../AbstractElements';
import { Container, Row, Col, Card, CardHeader,CardBody } from 'reactstrap'
import { InstructionsMediaOptions } from '../../../Constant';
import DropdownCommon from '../../Common/Dropdown';
  
const VideoLive = (props) => {

  // Constants
  const videoEl = useRef(null);

  
  // Global Variables

  // useState Constants

  // Functions

  // onload
  useEffect(()=>{
    if (!videoEl) {
      return
    }
    navigator.mediaDevices.getUserMedia({video:true})
      .then(stream => {
        let video = videoEl.current
        video.srcObject = stream
        video.play()
      })
  }, [videoEl])

 
  

  // HTML
  return (
    <Fragment>
      <Breadcrumbs parent="Users" title="Instructions" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
        
            <Col sm="12">
            <Card>
      <CardHeader className='card-no-border pb-0'>
        <div className='header-top'>
          <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'end' }} options={InstructionsMediaOptions} iconName='icon-more-alt' btn={{ tag: 'span' }} />
        </div>
      </CardHeader>
      <CardBody className='py-lg-3'>
      <video autoPlay ={true} id ="video" ref={videoEl}></video>
      </CardBody>
    </Card>



  
              
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default VideoLive;