import React,{useState} from 'react';
import {Container,Row,Col,Form,FormGroup,Input,Label,Button} from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import {postRequest} from "../../../Services/http-request-service.js";
import { sha3_512 } from 'js-sha3';
import { HTTP_RESOURCE_REGISTER,DOMAINURL } from '../../../Constant';

const RegisterSimple = (props) => {

  const navigate = useNavigate();

    const [togglePassword,setTogglePassword] = useState(false);
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [privacyPolicySettings, setPrivacyPolicySettings] = useState(false);

  async function register()
  {
    // Constants
    const URL = `${DOMAINURL}:3000${HTTP_RESOURCE_REGISTER}`;
    const salt = new Uint32Array(1);

    // Variables
    let data = {};

    // error check
    if (!privacyPolicySettings)
    {
      SweetAlert.fire({ title: "Error!", text: "Privacy Policy Must Be Accepted", icon: "error" });
      return;
    }

    if (email === "" || password === "")
    {
      SweetAlert.fire({ title: "Error!", text: "All fields are required", icon: "error" });
      return;
    }

    // create the salt
    window.crypto.getRandomValues(salt);

    // build the request data
    data = {"email":email,"hashedPassword":sha3_512(password),"salt":sha3_512(salt[0].toString())};

    // send the request
    try
    {
      await postRequest(URL,data);
      await SweetAlert.fire({ title: "Success!", text: "Registered Successfully! Redirecting to the login page", icon: "success" });
      navigate(`${process.env.PUBLIC_URL}/login`);
    }
    catch (error)
    {
      SweetAlert.fire({ title: "Error!", text: "Could not Register", icon: "error" });
      return;
    }
  }

    return (
      <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">     
          <div className="login-card">
            <div>
              <div><a className="logo" href="#javascript"><img className="img-fluid for-light" src={require("../../../assets/images/logo/login.png")} alt="looginpage"/><img className="img-fluid for-dark" src={require("../../../assets/images/logo/login.png")} alt="looginpage"/></a></div>
              <h4 align="center">{"SANO Healthcare"}</h4>
              <div className="login-main"> 
                <Form className="theme-form">
                  <h4>{"Create your account"}</h4>
                  <p>{"Enter your personal details to create account"}</p>
                  <FormGroup>
                    <Label className="col-form-label">{"Email Address"}</Label>
                    <Input className="form-control" type="email" required="" onChange={item => setEmail(item.target.value)} placeholder="Email"/>
                  </FormGroup>
                  <FormGroup>
                  <div className="mb-3 position-relative">
                        <Label className="col-form-label">{"Password"}</Label>
                        <Input id="userpassword" className="form-control" type={togglePassword ? "text" : "password"} onChange={item => setPassword(item.target.value)} placeholder="password" required="" />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span id="togglepassword" className={togglePassword ? "" : "show"}></span></div>
                      </div>                   
                  </FormGroup>
                  <div className="login-btn mb-0">
                    <div className="checkbox checkbox-primary">
                      <Input id="checkbox1" type="checkbox" checked={privacyPolicySettings} onChange={() => { setPrivacyPolicySettings(!privacyPolicySettings)}} />
                      <Label className="text-muted" for="checkbox1">{"Agree with"}<a className="ms-2" onClick={() => navigate(`${process.env.PUBLIC_URL}/privacypolicy`)} href="#/">{"Privacy Policy"}</a></Label>
                    </div>
                    <Button color="primary" type="button" onClick={register}>{"Register"}</Button>
                  </div>
                  <p className="mt-4 mb-0">{"Already have an account?"}<a className="ms-2" onClick={() => navigate(`${process.env.PUBLIC_URL}/login`)} href="#/">{"Sign In"}</a></p>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      </Container>
    );
}

export default RegisterSimple;