export async function postRequest (url,objectdata)
{
  // Constants
  const HEADERS = {'Content-Type': 'application/json;charset=utf-8','Accept': 'application/json'};
  const METHOD = 'POST';

  // Variables
  var response;
  var data;

  try
  {
    response = await fetch(url, {
      method: METHOD,
      headers: HEADERS,
      body: JSON.stringify(objectdata)
    });
    
    data = response.json();
    return response.ok ? data : data.then(Promise.reject.bind(Promise));
  }
  catch (error)
  {
    return Promise.reject(response);
  }
}

export async function deleteRequest (url,objectdata)
{
  // Constants
  const HEADERS = {'Content-Type': 'application/json;charset=utf-8','Accept': 'application/json'};
  const METHOD = 'DELETE';

  // Variables
  var response;
  var data;

  try
  {
    response = await fetch(url, {
      method: METHOD,
      headers: HEADERS,
      body: JSON.stringify(objectdata)
    });

    data = response.json();

    return response.ok ? data : data.then(Promise.reject.bind(Promise));
  }
  catch (error)
  {
    return Promise.reject(response);
  }
}

export async function putRequest (url,objectdata)
{
  // Constants
  const HEADERS = {'Content-Type': 'application/json;charset=utf-8','Accept': 'application/json'};
  const METHOD = 'PUT';

  // Variables
  var response;
  var data;

  try
  {
    response = await fetch(url, {
      method: METHOD,
      headers: HEADERS,
      body: JSON.stringify(objectdata)
    });

    data = response.json();

    return response.ok ? data : data.then(Promise.reject.bind(Promise));
  }
  catch (error)
  {
    return Promise.reject(response);
  }
}

export async function getRequest (url)
{
  // Constants
  const HEADERS = {'Accept': 'application/json'};
  const METHOD = 'GET';

  // Variables
  var response;
  var data;

  try
  {
    response = await fetch(url, {
      method: METHOD,
      headers: HEADERS
    });

    data = response.json();

    return response.ok ? data : data.then(Promise.reject.bind(Promise));
  }
  catch (error)
  {
    return Promise.reject(response);
  }
}