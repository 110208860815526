import React, { Fragment, useState, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import CountUp from 'react-countup';
import DataTable from 'react-data-table-component'
import { User, Clipboard, Trash} from 'react-feather';
import { Container, Row, Col, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter, CardBody,Form,FormGroup,Label,Input,Button } from 'reactstrap'
import SweetAlert from 'sweetalert2'
import {postRequest,deleteRequest,putRequest} from "../../../Services/http-request-service.js"
import { HTTP_RESOURCE_GET_MEDICINES,HTTP_RESOURCE_ADD_MEDICINES,HTTP_RESOURCE_EDIT_MEDICINES,HTTP_RESOURCE_DELETE_MEDICINES,DOMAINURL } from '../../../Constant';

const Medicines = () => {
 // Constants 
 const token = document.cookie.substring(6);

 // Table Data Constants
 const tableColumns = [
 {
   name: 'Name',
   selector: row => row.name,
   filterable: true,
 },  
 {
   name: 'Instructions',
   selector: row => row.instructions,
   filterable: true,
 },
 {
   name: 'SideEffects',
   selector: row => row.sideeffects,
   filterable: true,
 },
 {
   name: 'Edit',
   selector: row => row.edit,
   filterable: true,
   cell:(row) => <Clipboard id="editrow" onClick={() => {editMedicine(row);}}/>,
 },
 {
   name: 'Delete',
   selector: row => row.delete,
   filterable: true,
   cell:(row) => <Trash id="deleterow" onClick={() => {deleteMedicine(row.name);}}/>,
 }
];

 // Global Variables

 // useState Constants

 // modals
 const [modalAdd, setModalAdd] = useState(false);
 const toggleAdd = () => setModalAdd(!modalAdd);
 const [modalEdit, setModalEdit] = useState(false);
 const toggleEdit = () => setModalEdit(!modalEdit);

 // forms
 const [name, setName] = useState("");
 const [instructions, setInstructions] = useState("");
 const [sideeffects, setSideeffects] = useState("");

 // stats
 const [medicinesCount, setMedicinesCount] = useState(0);

 // Data Tables
 const [tableData, setTableData] = useState([]);

 // Functions

 // onload
 useEffect(() => {
  getMedicines();
 }, []);

 async function getMedicines()
 {
   // Constants
   const URL = `${DOMAINURL}:3000${HTTP_RESOURCE_GET_MEDICINES}`;

   // Variables
   let data = {};

   // error check
   if (token === "")
   {
    throw new Error("");
   }

   // build the request data
   data = {"authorization":token};

   // send the request
   try
   {
     setTableData([]);
     setMedicinesCount(0);
     const result = await postRequest(URL,data);
     setMedicinesCount(Object.keys(result).length);
     setTableData(result);
   }
   catch (error)
   {
    setMedicinesCount(0);
     SweetAlert.fire({ title: "Error!", text: "Could not get the users medicines list", icon: "error" });
     return;
   }
 }

 function editMedicine(row)
 {
   // set the text boxes in the edit modal
   setName(row.name);
   setInstructions(row.instructions);
   setSideeffects(row.sideeffects);

   // open the edit modal
   toggleEdit();
 }

 async function deleteMedicine(name)
 {
   // Constants
   const URL = `${DOMAINURL}:3000${HTTP_RESOURCE_DELETE_MEDICINES}`;

   // Variables
   let data = {};

   // error check
   if (token === "")
   {
    throw new Error("");
   }

   // ask to confirm to delete the patient
   let userData = await SweetAlert.fire({ title: "Warning!", text: `Are you sure you want to delete medicine ${name} ?`,showCancelButton: true,reverseButtons: true, icon: "warning",confirmButtonText: 'Yes, delete it!',cancelButtonText: 'No, cancel!', });

   if (!userData.isConfirmed)
   {
     return;
   }

   // build the request data
   data = {"authorization":token,"name":name};

   // send the delete request
   try
   {
     await deleteRequest(URL,data);
     getMedicines();
   }
   catch (error)
   {
     setMedicinesCount(0);
     setTableData([]);
     SweetAlert.fire({ title: "Error!", text: "Could not delete the medicine", icon: "error" });
     return;
   }
 }

 async function addMedicine()
 {
   // Constants
   const URL = `${DOMAINURL}:3000${HTTP_RESOURCE_ADD_MEDICINES}`;

   // Variables
   let data = {};

   // error check universal
   if (name === "" || instructions === "" || sideeffects === "")
   {
     SweetAlert.fire({ title: "Error!", text: "All data is required", icon: "error" });
     return;
   }
   
   if (token === "")
   {
    throw new Error("");
   }

   // build the post request data
   data = {"authorization":token,"name":name,"instructions":instructions,"sideeffects":sideeffects};

   // send the post request
   try
   {
     await postRequest(URL,data);
     getMedicines();
   }
   catch (error)
   {
     setMedicinesCount(0);
     setTableData([]);
     SweetAlert.fire({ title: "Error!", text: "Could not add the medicine", icon: "error" });
     return;
   }
 }

 async function editMedicineSave()
 {
   // Constants
   const URL = `${DOMAINURL}:3000${HTTP_RESOURCE_EDIT_MEDICINES}`;

   // Variables
   let data = {};

   // error check universal
   if (name === "" || instructions === "" || sideeffects === "")
   {
     SweetAlert.fire({ title: "Error!", text: "All data is required", icon: "error" });
     return;
   }
   
   if (token === "")
   {
     throw new Error("");
   }

   // build the post request data
   data = {"authorization":token,"name":name,"instructions":instructions,"sideeffects":sideeffects};

   // send the post request
   try
   {
     await putRequest(URL,data);
     getMedicines();
   }
   catch (error)
   {
     setTableData([]);
     SweetAlert.fire({ title: "Error!", text: "Could not edit the medicine", icon: "error" });
     return;
   }
 }
 

 // HTML
 return (
   <Fragment>
     <Breadcrumbs parent="Users" title="Main Menu" />
     <Container fluid={true}>
       <div className="user-profile">
         <Row>
         <Col sm="12" xl="12" lg="12">
             <Card className="o-hidden">
               <CardBody className="bg-primary b-r-4">
                 <div className="media static-top-widget">
                   <div className="align-self-center text-center"><User /></div>
                   <div className="media-body"><span className="m-0">{"Medicines"}</span>
                     <h4 className="mb-0 counter"><CountUp end={medicinesCount} /></h4>
                   </div>
                 </div>
               </CardBody>
             </Card>
           </Col>
           <Col sm="12">
             <Card>
                               <CardHeader>
                                   <h5>{"Medicines"}</h5>
                               </CardHeader>
                               <CardBody>






                               <Button id="addmedicinebutton" color="primary" onClick={toggleAdd}>{"Add Medicine"}</Button>
                     <Modal isOpen={modalAdd} toggle={toggleAdd} >
                         <ModalHeader toggle={toggleAdd}>{"Add Medicine"}</ModalHeader>
                         <ModalBody>
                         
                                              <FormGroup className="row">
                                              <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">{"Name"}</Label>
                                              <Col sm="12">
                                              <Input id="addmedicinename" className="form-control" type="text" selected={name} onChange={(item) => setName(item.target.value)} placeholder="Name" />
                                              </Col>
                                              </FormGroup> 

                                              <FormGroup className="row">
                                              <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">{"Instructions"}</Label>
                                              <Col sm="12">
                                              <Input id="addmedicineinstructions" className="form-control" type="text" selected={instructions} onChange={(item) => setInstructions(item.target.value)} placeholder="Instructions" />
                                              </Col>
                                              </FormGroup> 

                                              <FormGroup className="row">
                                              <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">{"Side Effects"}</Label>
                                              <Col sm="12">
                                              <Input id="addmedicinesideeffects" className="form-control" type="tel" selected={sideeffects} onChange={(item) => setSideeffects(item.target.value)} placeholder="Side Effects" />
                                              </Col>
                                              </FormGroup> 

                                              
                         </ModalBody>
                         <ModalFooter>
                         <Button color="primary" onClick={toggleAdd}>{"Close"}</Button>
                         <Button id="addmedicinesaddbutton" color="secondary" onClick={() => {toggleAdd(); addMedicine();}}>{"Add Medicine"}</Button>
                         </ModalFooter>
                     </Modal>







                     <Modal isOpen={modalEdit} toggle={toggleEdit} >
                         <ModalHeader toggle={toggleEdit}>{"Edit Medicine"}</ModalHeader>
                         <ModalBody>
                          
                                              <FormGroup className="row">
                                              <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">{"Instructions"}</Label>
                                              <Col sm="12">
                                              <Input id="editmedicineinstructions" className="form-control" type="text" selected={instructions} onChange={(item) => setInstructions(item.target.value)} value={instructions} placeholder="Instructions" />
                                              </Col>
                                              </FormGroup> 

                                              <FormGroup className="row">
                                              <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">{"Side Effects"}</Label>
                                              <Col sm="12">
                                              <Input id="editmedicinesideeffects" className="form-control" type="tel" selected={sideeffects} onChange={(item) => setSideeffects(item.target.value)} value={sideeffects} placeholder="Side Effects" />
                                              </Col>
                                              </FormGroup> 

                                              
                         </ModalBody>
                         <ModalFooter>
                         <Button color="primary" onClick={toggleEdit}>{"Close"}</Button>
                         <Button id="editmedicinesaddbutton" color="secondary" onClick={() => {toggleEdit(); editMedicineSave();}}>{"Edit Medicine"}</Button>
                         </ModalFooter>
                     </Modal>








                                   <div id="the-basics">
                                       <Form>
                                           <div><br></br>
                                           <DataTable
                 data={tableData}
                 columns={tableColumns}
                 striped={true}
                 center={true}
                 Clicked
                 pagination
                 paginationComponentOptions={{selectAllRowsItem: true,selectAllRowsItemText: "ALL"}}
               />
                     </div>
                
                                         
                                       </Form>
                                   </div>
                               </CardBody>
                           </Card>
           </Col>
         </Row>
       </div>
     </Container>
   </Fragment>
 );
};

export default Medicines;
