import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs, H5, LI, UL } from '../../../AbstractElements';
import { UserMinus, UserPlus } from 'react-feather';
import { Container, Row, Col, Card, CardHeader,CardBody } from 'reactstrap'
import { InstructionsMediaOptions } from '../../../Constant';
import DropdownCommon from '../../Common/Dropdown';
  
const Instructions = (props) => {

  // Constants

  // Global Variables

  // useState Constants

  // Functions

  // onload
  useEffect(() => {
  }, []);

  // HTML
  return (
    <Fragment>
      <Breadcrumbs parent="Users" title="Instructions" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
        
            <Col sm="12">
            <Card>
      <CardHeader className='card-no-border pb-0'>
        <div className='header-top'>
          <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'end' }} options={InstructionsMediaOptions} iconName='icon-more-alt' btn={{ tag: 'span' }} />
        </div>
      </CardHeader>
      <CardBody className='py-lg-3'>
        <UL attrUL={{ className: 'user-list' }}>
          <LI>
            <div className='user-icon primary'>
              <div className='user-box'>
                <UserPlus className='font-primary' />
              </div>
            </div>
            <div>
              <H5 attrH5={{ className: 'mb-1' }}>Diagnosis</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>Essential Hypertension</span>
              </span>
            </div>
          </LI>
          <LI>
            <div className='user-icon success'>
              <div className='user-box'>
                <UserMinus className='font-success' />
              </div>
            </div>
            <div>
            <H5 attrH5={{ className: 'mb-1' }}>Pill Strength</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>20 mg tablets</span>
              </span>
            </div>
          </LI>
        </UL>
      </CardBody>
    </Card>



    <Card>
      <CardHeader className='card-no-border pb-0'>
        <div className='header-top'>
          <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'end' }} options={InstructionsMediaOptions} iconName='icon-more-alt' btn={{ tag: 'span' }} />
        </div>
      </CardHeader>
      <CardBody className='py-lg-3'>
        <UL attrUL={{ className: 'user-list' }}>
          <LI>
            <div className='user-icon primary'>
              <div className='user-box'>
                <UserPlus className='font-primary' />
              </div>
            </div>
            <div>
              <H5 attrH5={{ className: 'mb-1' }}>Pill Frequency</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>2 per day</span>
              </span>
            </div>
          </LI>
          <LI>
            <div className='user-icon success'>
              <div className='user-box'>
                <UserMinus className='font-success' />
              </div>
            </div>
            <div>
            <H5 attrH5={{ className: 'mb-1' }}>Pill Instructions</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>Without food</span>
              </span>
            </div>
          </LI>
        </UL>
      </CardBody>
    </Card>




    <Card>
      <CardHeader className='card-no-border pb-0'>
        <div align="center" className='header-top'>
          <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'end' }} options={InstructionsMediaOptions} iconName='icon-more-alt' btn={{ tag: 'span' }} />
        </div>
      </CardHeader>
      <CardBody className='py-lg-3'>
      <UL attrUL={{ className: 'user-list' }}>
          <LI>
            <div className='user-icon primary'>
              <div className='user-box'>
                <UserPlus className='font-primary' />
              </div>
            </div>
            <div>
              <H5 attrH5={{ className: 'mb-1' }}>Reason Taking</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>DATA</span>
              </span>
            </div>
          </LI>
          <LI>
            <div className='user-icon success'>
              <div className='user-box'>
                <UserMinus className='font-success' />
              </div>
            </div>
            <div>
            <H5 attrH5={{ className: 'mb-1' }}>Side Effects</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>DATA</span>
              </span>
            </div>
          </LI>
        </UL>
      </CardBody>
    </Card>
    
              
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default Instructions;