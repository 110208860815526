import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {postRequest} from "../Services/http-request-service.js";
import { HTTP_RESOURCE_CHECK_AUTHENTICATION,DOMAINURL } from '../Constant';

const PrivateRoute = () => {
  const token = document.cookie.substring(6);
  console.log(token);

  var login = false;

  useEffect(async () => {
    // Constants
    const URL = `${DOMAINURL}:3000${HTTP_RESOURCE_CHECK_AUTHENTICATION}`;
    
    // Variables
    let data = {};

    // error check
    if (token === "")
    {
      login = false;
    }

    // build the request data
    data = {"authorization":token};

    // send the request
    try
    {
      await postRequest(URL,data);
      login = true;
    }
    catch (error)
    {
      login = false;
    }
  }, []);
  return token || login ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />;
};

export default PrivateRoute;