import { Home } from 'react-feather'
export const MENUITEMS = [
    {
        menutitle: "General",
        menucontent: "Dashboards",
        Items: [           
            { path: `${process.env.PUBLIC_URL}/dashboard/default`, icon: Home, title: 'Home', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/dashboard/default/DataUser/name`, icon: Home, title: 'DataUser', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/dashboard/default/Instructions/name`, icon: Home, title: 'Instructions', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/dashboard/default/Medicines`, icon: Home, title: 'Medicines', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/dashboard/default/VideoInstructions`, icon: Home, title: 'Video Instructions', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/dashboard/default/VideoLive`, icon: Home, title: 'Video Live', type: 'link' }
        ]
    },
]