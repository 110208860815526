import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs} from '../../../AbstractElements';
import { Container, Row, Col, Card, CardHeader,CardBody } from 'reactstrap'
import { InstructionsMediaOptions } from '../../../Constant';
import DropdownCommon from '../../Common/Dropdown';
  
const VideoInstructions = (props) => {

  
  // Global Variables

  // useState Constants

  // Functions

  // onload
  useEffect(() => {
    
  }, []);

 
  

  // HTML
  return (
    <Fragment>
      <Breadcrumbs parent="Users" title="Instructions" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
        
            <Col sm="12">
            <Card>
      <CardHeader className='card-no-border pb-0'>
        <div className='header-top'>
          <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'end' }} options={InstructionsMediaOptions} iconName='icon-more-alt' btn={{ tag: 'span' }} />
        </div>
      </CardHeader>
      <CardBody className='py-lg-3'>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/H4TyemDnrB0?si=F4QOj5yUQuRwKSci" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </CardBody>
    </Card>



  
              
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default VideoInstructions;