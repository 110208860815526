import React, { Fragment, useState, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import CountUp from 'react-countup';
import DataTable from 'react-data-table-component'
import { Container, Row, Col, Card, CardHeader, CardBody,Form } from 'reactstrap'
import SweetAlert from 'sweetalert2'
import {postRequest} from "../../../Services/http-request-service.js"
import { HTTP_RESOURCE_GET_MEDICINES,DOMAINURL } from '../../../Constant';

const Dashboard = () => {
 // Constants
 const token = document.cookie.substring(6);

 // Table Data Constants
 const tableColumns = [
 {
   name: 'Name',
   selector: row => row.name,
   filterable: true,
 },  
 {
   name: 'Instructions',
   selector: row => row.instructions,
   filterable: true,
 },
 {
   name: 'SideEffects',
   selector: row => row.sideeffects,
   filterable: true,
 }
];


 const datafortables = [
 {
   name: 'name',
   instructions: 'instructions',
   sideeffects: 'sideeffects',
 },
 {
  name: 'name',
  instructions: 'instructions',
  sideeffects: 'sideeffects',
 },
 {
  name: 'name',
  instructions: 'instructions',
  sideeffects: 'sideeffects',
 }
];

 // Global Variables

 // useState Constants

 // stats
 const [reportsCount, setReportsCount] = useState(0);

 // Data Tables
 const [tableData, setTableData] = useState([]);

 // Functions

 // onload
 useEffect(() => {
  getMedicines();
 }, []);

 async function getMedicines()
 {
   // Constants
   const URL = `${DOMAINURL}:3000${HTTP_RESOURCE_GET_MEDICINES}`;

   // Variables
   let data = {};

   // error check
   if (token === "")
   {
    throw new Error("");
   }

   // build the request data
   data = {"authorization":token};

   // send the request
   try
   {
     setTableData(datafortables);
     setReportsCount(0);
     const result = await postRequest(URL,data);
     setReportsCount(Object.keys(result).length);
     setTableData(result);
   }
   catch (error)
   {
     setReportsCount(0);
     SweetAlert.fire({ title: "Error!", text: "Could not get the users medicines list", icon: "error" });
     return;
   }
 }
 

 // HTML
 return (
  <Fragment>
    <Breadcrumbs parent="Users" title="Home" />
    <Container fluid={true}>
      <div className="user-profile">
        <Row>
          <Col sm="12" xl="12" lg="12">
            <Card className="o-hidden">
              <CardBody className="bg-primary b-r-4">
                <div className="media static-top-widget">
                
                  <div className="media-body"><span className="m-0">{"Medicines"}</span>
                    <h4 className="mb-0 counter"><CountUp end={reportsCount} /></h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12">
            <Card>
                              <CardHeader>
                                  <h5>{"Medicines"}</h5>
                              </CardHeader>
                              <CardBody>


                             



                                  <div id="the-basics">
                                      <Form>
                                          <div><br></br>
                                          <DataTable
                data={tableData}
                columns={tableColumns}
                striped={true}
                center={true}
                Clicked
                pagination
                paginationComponentOptions={{selectAllRowsItem: true,selectAllRowsItemText: "ALL"}}
              />
                    </div>
               
                                        
                                      </Form>
                                  </div>
                              </CardBody>
                          </Card>
          </Col>
        </Row>
      </div>
    </Container>
  </Fragment>
);
};

export default Dashboard;
