import React, { Fragment, useState, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { Typeahead } from 'react-bootstrap-typeahead';
import Chart from 'react-apexcharts';
import {radialChartLive } from '../../Common/Data/ApexChart';
import { User} from 'react-feather';
import { Container, Row, Col, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter, CardBody,FormGroup,Button } from 'reactstrap'

const DataUser = () => {

 // Type Ahead Data
 const medicineTyped = [
   {"name": "Lisinopril"},
   {"name":"1Lisinopril"},
   {"name":"2Lisinopril"},
 ];

 // Global Variables

 // useState Constants

 // modals
 const [modalAdd, setModalAdd] = useState(false);
 const toggleAdd = () => setModalAdd(!modalAdd);

 // forms
 const [medicine, setMedicine] = useState([]);

 // Functions

 // onload
 useEffect(() => {
 }, []);

 // HTML
 return (
   <Fragment>
     <Breadcrumbs parent="Users" title="Main Menu" />
     <Container fluid={true}>
       <div className="user-profile">
         <Row>
         <Col sm="12" xl="12" lg="12">
             <Card className="o-hidden">
               <CardBody className="bg-primary b-r-4">
                 <div className="media static-top-widget">
                   <div className="align-self-center text-center"><User /></div>
                   <div className="media-body"><h4 align="center" className="m-2">{"Welcome Walter"}</h4>
                    
                   </div>
                 </div>
               </CardBody>
             </Card>
           </Col>

           
           
           <Col sm="12">
             <Card>
                               <CardHeader>
                                   <h4>{"Medication"}</h4>
                               </CardHeader>
                               <CardBody>




                               <h5>{"Lisinopril"}</h5>
                               <img className="img-fluid for-light" src={require("../../../assets/images/logo/lisinopril.jpg")} alt="" />
                               <img className="img-fluid for-dark" src={require("../../../assets/images/logo/lisinopril.jpg")} alt="" />
                               <img className="img-fluid for-light" src={require("../../../assets/images/logo/lisinopril-pill.jpg")} alt="" />
                               <img className="img-fluid for-dark" src={require("../../../assets/images/logo/lisinopril-pill.jpg")} alt="" />
                               <br></br>

                               <Button color="primary" onClick={toggleAdd}>{"Refill Request"}</Button>
                     <Modal isOpen={modalAdd} toggle={toggleAdd} >
                         <ModalHeader toggle={toggleAdd}>{"Confirm Refill Request"}</ModalHeader>
                         <ModalBody>
                         
                                              <FormGroup className="row">
                                              Medicine <Typeahead id="basic-typeahead" labelKey="name" multiple={false} options={medicineTyped} selected={medicine} onMenuToggle={() => {if (medicine.length > 0) {setMedicine([])}}} onChange={(item) => setMedicine(item)} placeholder="Medicine"/><br></br>
                                              </FormGroup> 

                                              
                         </ModalBody>
                         <ModalFooter>
                         <Button color="primary" onClick={toggleAdd}>{"Close"}</Button>
                         <Button color="secondary" onClick={() => {toggleAdd();}}>{"Confirm Refill Request"}</Button>
                         </ModalFooter>
                     </Modal>







                    
                               </CardBody>
                           </Card>
                           <Card>
          <CardHeader>
            <Row>
              <Col xs='9'>
                <h5>{"Adherence"}</h5>
              </Col>
              <Col xs='3' className='text-end'>
              
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
          <h5>{"Lisinopril"}</h5>
            <div className='chart-container'>
              <div id='circlechart'>
                <Chart options={radialChartLive.options} series={radialChartLive.series} height='200' type='radialBar' />
              </div>
            </div>
          </CardBody>
        </Card>
           </Col>
         </Row>
       </div>
     </Container>
   </Fragment>
 );
};

export default DataUser;
